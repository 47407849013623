<template>
  <CommonDialog>
    <div class="dialog-header">
      <button type="button" class="dialog-close" v-on:click="cancel">
        <IconClose class="icon" />
      </button>
      <div class="title">{{ organization.name }}</div>
    </div>
    <div class="dialog-body">
      <div class="organization-detail">
        <div class="organization-desc fullwidth" v-html="organization.desc"></div>
      </div>
    </div>
  </CommonDialog>
</template>

<script>
import CommonDialog from "@/components/common/dialog"

import IconClose from "@/assets/image/icon/close.svg"

export default {
  name: "OrganizationDescription",
  components: {
    CommonDialog,
    IconClose
  },
  props: {
    organization: Object
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    cancel() {
      this.$emit("callback", { action: "cancel" })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-header {
  >.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.organization-detail {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  
  .organization-title {
    font-size: 16px;
    font-weight: 700;
  }
  
  .organization-desc {
    font-size: 12px;
    font-weight: 400;
  }
}
</style>
