<template>
  <div class="content" ref="content">
    <div class="content-wrapper" ref="wrapper">
      <div class="content-title">Program Rekomendasi</div>
      <ProgramRecommendation />
      <div class="content-title">Program Lainnya</div>
      <ProgramOthers ref="others" />
    </div>
  </div>
</template>

<script>
import qbi from "@/qbi"

import ProgramRecommendation from "./recommendation"
import ProgramOthers from "./others"

export default {
  name: "ProgramIndex",
  components: {
    ProgramRecommendation,
    ProgramOthers,
  },
  created() {
    qbi.set.header(true)
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    onScroll() {
      if (this.$parent?.$parent?.$refs?.header && this.$refs.content && this.$refs.others) {
        const scrollTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) - this.$parent?.$parent?.$refs?.header.offsetHeight
        const height = window.innerHeight
        if (scrollTop + height >= this.$refs.content.offsetHeight - 500) {
          this.$refs.others.getProgram()
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  padding: 16px 0;

  >.content-title {
    padding: 0 16px;
    font-size: 16px;
    font-weight: 700;
  }

}
</style>
