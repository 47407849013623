<template>
  <router-link :to="`/transaction/${transaction.order_id}`" class="transaction-card">
    <div class="transaction-image" :style="{ backgroundImage: `url(${transaction.program.image})` }" ref="image"></div>
    <div class="transaction-content">
      <div class="transaction-detail">
        <div class="transaction-caption">
          <div class="title">{{ transaction.program.name }}</div>
          <div class="subtitle">Rp {{ $filters.numberFormat(transaction.amount) }} - {{ $filters.dateFormat(transaction.transaction_time, "DD MMMM YYYY") }}</div>
        </div>
        <button type="button" class="btn" v-bind:class="paymentStyle()">{{ paymentLabel() }}</button>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "TransactionCard",
  components: {
  },
  props: {
    transaction: Object
  },
  mounted() {
    this.resizeImage()
    window.addEventListener("resize", this.resizeImage)
  },
  unmounted() {
    this.active = false
    window.removeEventListener("resize", this.resizeImage)
  },
  updated() {
    this.resizeImage()
  },
  methods: {
    resizeImage() {
      if (this.$refs.image) {
        this.$refs.image.style.height = `${(this.$refs.image.clientWidth * 124 / 328)}px`
      }
    },
    paymentStyle() {
      let style = `btn-disabled`
      switch (this.transaction?.status) {
        case "pending": style = `btn-warning`; break
        case "settlement": style = `btn-primary`; break
        case "expire": style = `btn-disabled`; break
      }
      return style
    },
    paymentLabel() {
      let label = `Dibatalkan`
      switch (this.transaction?.status) {
        case "pending": label = `Pending`; break
        case "settlement": label = `Berhasil`; break
        case "expire": label = `Dibatalkan`; break
      }
      return label
    }
  }
}
</script>

<style lang="scss" scoped>
.transaction-card {
  display: flex;
  flex-direction: column;

  >.transaction-image {
    width: 100%;
    height: 180px;
    background-color: #E5E5E5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
  }

  >.transaction-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-top: none;
    -webkit-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;

    >.transaction-detail {
      display: flex;
      align-items: center;
      gap: 10px;

      >.transaction-caption {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 6px;
        line-height: normal;
        overflow: hidden;

        >.title {
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        >.subtitle {
          color: #999999;
        }
      }

      >button {
        height: 34px;
        font-size: 12px;
        font-weight: 700;
        -webkit-border-radius: 17px;
        border-radius: 17px;
      }
    }
  }
}
</style>
