<template>
  <div class="card-container">
    <div class="card-header">
      <div class="title">Kabar Terbaru</div>
      <router-link :to="`/${program.slug}/update`" class="action" v-if="update">Lihat Detail</router-link>
    </div>
    <div class="card-body">
      <div class="program-update" v-if="update">
        <div class="update-header">
          <div class="header-icon">
            <IconAddCard class="icon" v-if="update.type === 'settlement'" />
            <IconCleanHands class="icon" v-if="update.type === 'distribution'" />
          </div>
          <div class="header-caption">
            <div class="title">{{ update.title }}</div>
            <div class="subtitle">{{ $filters.dateFormat(update.datetime, "dddd, DD MMMM YYYY") }}</div>
          </div>
        </div>
        <div class="update-content" v-html="update.desc"></div>
      </div>
      <div class="card-empty" v-if="!update">
        <div class="title">Belum Ada Kabar Terbaru</div>
        <div class="subtitle">Belum ada kabar terbaru dari program ini</div>
      </div>
    </div>
  </div>
</template>

<script>
import IconCleanHands from "@/assets/image/icon/clean-hands.svg"
import IconAddCard from "@/assets/image/icon/add-card.svg"

export default {
  name: "ProgramDetailUpdate",
  inject: ["$axios"],
  components: {
    IconCleanHands,
    IconAddCard
  },
  props: {
    program: Object
  },
  data() {
    return {
      update: null
    }
  },
  created() {
    this.getUpdate()
  },
  methods: {
    getUpdate() {
      this.$axios.get(`program/${this.program.id}/update`, {
        params: {
          start: 0,
          limit: 1
        }
      }).then(response => {
        if (response.data?.data && response.data?.data.length) this.update = response.data.data[0]
      }).catch(error => {
        console.error(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.program-update {
  display: flex;
  flex-direction: column;
  gap: 10px;

  >.update-header {
    display: flex;
    align-items: center;
    gap: 10px;

    >.header-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      background-color: #F8F8F8;
      -webkit-border-radius: 17px;
      border-radius: 17px;

      >.icon {
        width: 20px;
      }
    }

    >.header-caption {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      line-height: normal;

      >.title {
        font-size: 14px;
        font-weight: 700;
      }

      >.subtitle {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }

  >.update-content {
    img {
      max-width: 100%;
    }
  }
}
</style>
