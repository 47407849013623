<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Quran Belajar Indonesia` : `Quran Belajar Indonesia` }}</template>
  </metainfo>
  <div class="loading" v-if="loading">
    <div class="loading-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="header" v-if="header" ref="header">
    <img class="logo" src="@/assets/image/logo.png" />
    <div class="spacer"></div>
    <button type="button" class="btn btn-primary" v-on:click="download">Download</button>
    <router-link to="/transaction" v-if="history.length">
      <IconArticle class="icon icon-black" />
    </router-link>
  </div>
  <router-view />
</template>

<script>
import { useMeta } from "vue-meta"

import qbi from "./qbi"

import IconArticle from "@/assets/image/icon/article.svg"

export default {
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: 'id', amp: true }
    })
  },
  name: 'App',
  inject: ['constant'],
  components: {
    IconArticle
  },
  computed: {
    loading: {
      get: function () {
        return qbi.get.loading()
      },
      set: function () {
        return false
      }
    },
    header: {
      get: function () {
        return qbi.get.header()
      },
      set: function () {
        return false
      }
    }
  },
  data() {
    return {
      history: []
    }
  },
  created() {
    this.getHistory()
  },
  methods: {
    getHistory() {
      try {
        this.history = JSON.parse(window.localStorage.getItem("history")) || []
      } catch (error) {
        console.error(error)
      }
    },
    download() {
      window.open(this.constant.DOWNLOAD_URL)
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000a6;
  z-index: 9999;
}

.loading-ring {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);

  >div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80px;
    height: 80px;
    border: 6px solid #00ffe1;
    border-radius: 50%;
    animation: loading-ring 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #00ffe1 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.3s;
    }

    &:nth-child(2) {
      animation-delay: -0.2s;
    }

    &:nth-child(3) {
      animation-delay: -0.1s;
    }
  }
}

@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  padding: 0 16px;
  height: 54px;
  background-color: #FFFFFF;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.08);
  z-index: 9;

  >.logo {
    height: 36px;
  }

  >.spacer {
    flex: 1;
  }

  >.btn {
    padding: 6px 10px;
    font-weight: 700;
  }

  >a {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
}</style>
