<template>
  <div class="navigation" ref="navgation">
    <a v-on:click="goBack">
      <IconArrowLeft class="icon" />
    </a>
    <div class="title">Riwayat Infaq</div>
  </div>
  <div class="content" ref="content">
    <div class="content-wrapper">
      <div class="transaction-empty" v-if="!transactions.length">
        <div class="title">Belum Ada Riwayat Infaq</div>
        <div class="subtitle">Anda belum memiliki riwayat infaq</div>
      </div>
      <TransactionCard v-bind:transaction="transaction" v-for="transaction in transactions" v-bind:key="transaction.id" />
    </div>
  </div>
</template>

<script>
import qbi from "@/qbi"

import IconArrowLeft from "@/assets/image/icon/arrow-left.svg"

import TransactionCard from "./card"

export default {
  name: "TransactionIndex",
  inject: ["$axios"],
  components: {
    TransactionCard,
    IconArrowLeft
  },
  data() {
    return {
      limit: 10,
      loading: false,
      history: [],
      transactions: []
    }
  },
  created() {
    qbi.set.header(false)
    this.getHistory()
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    goBack() {
      if (window.history.state.back) this.$router.go(-1)
      else this.$router.replace({ name: "program-list" })
    },
    onScroll() {
        if (this.$refs.navigation && this.$refs.content) {
          const scrollTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) - this.$refs.navigation.offsetHeight
          const height = window.innerHeight
          if (scrollTop + height >= this.$refs.content.offsetHeight - 400) {
            this.getTransaction()
          }
        }
        if (this.$refs.content && this.$refs.wrapper) {
          const target = this.$refs.wrapper.offsetHeight - this.$refs.content.offsetHeight
          if (target > 0 && this.$refs.content.scrollTop >= target) {
            this.getTransaction()
          }
        }
    },
    takeHistory() {
      let history = []
      if (this.history.length) {
        const size = this.history.length > this.limit ? this.limit : this.history.length
        for (let index = 0; index < size; index++) {
          history.push(this.history.pop())
        }
      }
      return history
    },
    returnHistory(history) {
      const size = history.length
      for (let index = 0; index < size; index++) {
        this.history.push(history.pop())
      }
    },
    getHistory() {
      try {
        this.history = JSON.parse(window.localStorage.getItem("history")) || []
      } catch (error) {
        console.error(error)
      }
      if (this.history) this.getTransaction()
    },
    getTransaction() {
      if (this.loading) return
      const history = this.takeHistory()
      if (!history.length) return
      
      this.loading = true
      qbi.set.loading(true)

      this.$axios.get(`transaction`, {
        params: {
          history: history.join(",")
        }
      }).then(response => {
        this.loading = false
        qbi.set.loading(false)
        if (response.data?.data) this.transactions = [...this.transactions, ...response.data.data]
      }).catch(error => {
        this.loading = false
        qbi.set.loading(false)
        this.returnHistory(history)
        console.error(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.transaction-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 24px;

  >.title {
    font-size: 14px;
    font-weight: 700;
  }
}
</style>
