<template>
  <div class="card-container">
    <div class="card-header">
      <div class="title">Penggalang Dana</div>
      <router-link :to="`/organization/${program.organization.slug}`" class="action">Lihat Profil</router-link>
    </div>
    <div class="card-body">
      <div class="organization-detail">
        <div class="organization-image" :style="{ backgroundImage: `url(${program.organization.image})` }"></div>
        <div class="organization-caption">
          <div class="title">{{ program.organization.name }}
            <IconVerified class="icon" />
          </div>
          <div class="subtitle">Lembaga sudah Terverifikasi</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconVerified from "@/assets/image/icon/verified.svg"

export default {
  name: "ProgramDetailOrganization",
  props: {
    program: Object
  },
  components: {
    IconVerified
  }
}
</script>

<style lang="scss" scoped>
.organization-detail {
  display: flex;
  gap: 16px;
  
  >.organization-image {
    width: 48px;
    height: 48px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #EAEAEA;
    -webkit-border-radius: 24px;
    border-radius: 24px;
  }
  
  >.organization-caption {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    >.title {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 700;
      white-space: normal;
      
      >.icon {
        width: auto;
        height: 16px;
      }
    }
    
    >.subtitle {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
  }
}
</style>
