<template>
  <div class="navigation" ref="navigation">
    <a v-on:click="goBack">
      <IconArrowLeft class="icon" />
    </a>
    <div class="navigation-header" v-if="organization">
      <div class="navigation-image" :style="{backgroundImage: `url(${organization.image})`}"></div>
      <div class="navigation-caption">
        <div class="title">{{ organization.name }}
          <IconVerified class="icon" />
        </div>
        <div class="subtitle">Lembaga sudah Terverifikasi</div>
      </div>
    </div>
  </div>
  <div class="content" v-if="organization" ref="content">
    <div class="content-wrapper">
      <CommonCard v-bind:card="{ title: `Tentang`, label: `Lihat Detail`, desc: organization.short_desc, action: `open` }" @callback="descriptionCallback" />
      <ProgramCard v-bind:program="program" v-for="program in programs" :key="program.id" />
    </div>
  </div>
  <Transition>
    <OrganizationDescription class="fullscreen" v-bind:organization="organization" @callback="descriptionCallback" v-if="description" />
  </Transition>
</template>

<script>
import qbi from "@/qbi"

import IconArrowLeft from "@/assets/image/icon/arrow-left.svg"
import IconVerified from "@/assets/image/icon/verified.svg"

import CommonCard from "@/components/common/card"
import ProgramCard from "@/components/program/card"
import OrganizationDescription from "@/components/organization/description"

export default {
  name: "OrganizationDetail",
  inject: ["$axios"],
  components: {
    IconArrowLeft,
    IconVerified,
    CommonCard,
    ProgramCard,
    OrganizationDescription
  },
  props: {
    slug: String
  },
  data() {
    return {
      start: 0,
      limit: 10,
      loading: false,
      organization: null,
      description: false,
      programs: []
    }
  },
  created() {
    qbi.set.header(false)
    this.getOrganization()
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    goBack() {
      if (window.history.state.back) this.$router.go(-1)
      else this.$router.replace({ name: "program-list" })
    },
    onScroll() {
        if (this.organization && this.$refs.navigation && this.$refs.content) {
          const scrollTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) - this.$refs.navigation.offsetHeight
          const height = window.innerHeight
          if (scrollTop + height >= this.$refs.content.offsetHeight - 500) {
            this.getProgram(this.organization.id)
          }
        }
    },
    getOrganization() {
      qbi.set.loading(true)
      this.$axios.get(`organization/${this.slug}`).then(response => {
        qbi.set.loading(false)
        if (response.data?.data) {
          this.organization = response.data.data
          this.getProgram(this.organization.id)
        }
      }).catch(error => {
        qbi.set.loading(false)
        if (error.response.status === 404) this.goBack()
      })
    },
    getProgram(id) {
      if (this.start === null) return
      if (this.loading) return

      this.loading = true
      qbi.set.loading(true)

      this.$axios.get(`program`, {
        params: {
          organization: id,
          start: this.start,
          limit: this.limit
        }
      }).then(response => {
        this.loading = false
        qbi.set.loading(false)
        if (response.data?.data) {
          this.start = response.data.data.length < this.limit ? null : this.start + this.limit
          this.programs = [...this.programs, ...response.data.data]
        }
      }).catch(error => {
        this.loading = false
        qbi.set.loading(false)
        console.error(error)
      })
    },
    descriptionCallback(event) {
      this.description = event?.action === "open"
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 44px;
  height: auto;
  
  >.navigation-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    >.navigation-caption {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      line-height: normal;

      >.title {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 700;

        >.icon {
          width: auto;
          height: 16px;
        }
      }
    }
  
    >.navigation-image {
      width: 54px;
      height: 54px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border: 1px solid #EAEAEA;
      -webkit-border-radius: 27px;
      border-radius: 27px;
    }
  }
}
</style>