<template>
  <div class="navigation" ref="navigation">
    <a v-on:click="goBack">
      <IconArrowLeft class="icon" />
    </a>
    <div class="title">Kabar Terbaru</div>
  </div>
  <div class="content" ref="content">
    <div class="content-wrapper">
      <div class="card-container" v-for="update in updates" v-bind:key="update.id">
        <div class="card-body">
          <div class="program-update" v-if="update">
            <div class="update-header">
              <div class="header-icon">
                <IconAddCard class="icon" v-if="update.type === 'settlement'" />
                <IconCleanHands class="icon" v-if="update.type === 'distribution'" />
              </div>
              <div class="header-caption">
                <div class="title">{{ update.title }}</div>
                <div class="subtitle">{{ $filters.dateFormat(update.datetime, "dddd, DD MMMM YYYY") }}</div>
              </div>
            </div>
            <div class="update-content" v-html="update.desc"></div>
          </div>
        </div>
      </div>
      <div class="card-container" v-if="!updates.length">
        <div class="card-body">
          <div class="card-empty">
            <div class="title">Belum Ada Kabar Terbaru</div>
            <div class="subtitle">Belum ada kabar terbaru dari program ini</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qbi from "@/qbi"

import IconArrowLeft from "@/assets/image/icon/arrow-left.svg"
import IconCleanHands from "@/assets/image/icon/clean-hands.svg"
import IconAddCard from "@/assets/image/icon/add-card.svg"

export default {
  name: "ProgramUpdate",
  inject: ["$axios"],
  components: {
    IconArrowLeft,
    IconCleanHands,
    IconAddCard
  },
  props: {
    slug: String
  },
  data() {
    return {
      start: 0,
      limit: 10,
      loading: false,
      program: null,
      updates: []
    }
  },
  created() {
    qbi.set.header(false)
    this.getProgram()
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    goBack() {
      if (window.history.state.back) this.$router.go(-1)
      else this.$router.replace({ name: "program-detail", slug: this.slug })
    },
    onScroll() {
        if (this.program && this.$refs.navigation && this.$refs.content) {
          const scrollTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) - this.$refs.navigation.offsetHeight
          const height = window.innerHeight
          if (scrollTop + height >= this.$refs.content.offsetHeight - 500) {
            this.getUpdate()
          }
        }
    },
    getProgram() {
      qbi.set.loading(true)
      this.$axios.get(`program/${this.slug}`).then(response => {
        qbi.set.loading(false)
        if (response.data?.data) {
          this.program = response.data.data
          this.getUpdate()
        }
      }).catch(error => {
        qbi.set.loading(false)
        console.error(error)
      })
    },
    getUpdate() {
      if (this.start === null) return
      if (this.loading) return

      this.loading = true
      qbi.set.loading(true)

      this.$axios.get(`program/${this.program.id}/update`, {
        params: {
          start: this.start,
          limit: this.limit
        }
      }).then(response => {
        this.loading = false
        qbi.set.loading(false)
        if (response.data?.data) {
          this.start = response.data.data.length < this.limit ? null : this.start + this.limit
          this.updates = [...this.updates, ...response.data.data]
        }
      }).catch(error => {
        this.loading = false
        qbi.set.loading(false)
        console.error(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.program-update {
  display: flex;
  flex-direction: column;
  gap: 10px;

  >.update-header {
    display: flex;
    align-items: center;
    gap: 10px;

    >.header-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      background-color: #F8F8F8;
      -webkit-border-radius: 17px;
      border-radius: 17px;

      >.icon {
        width: 20px;
      }
    }

    >.header-caption {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      line-height: normal;

      >.title {
        font-size: 14px;
        font-weight: 700;
      }

      >.subtitle {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }

  >.update-content img {
    max-width: 100%;
  }
}
</style>
