<template>
  <div class="program-others">
    <div class="program-list">
      <ProgramCard v-bind:program="program" v-for="program in programs" :key="program.id" />
    </div>
  </div>
</template>

<script>
import qbi from "@/qbi"

import ProgramCard from "./card"

export default {
  name: "ProgramOthers",
  inject: ["$axios"],
  components: {
    ProgramCard
  },
  data() {
    return {
      start: 0,
      limit: 10,
      loading: false,
      programs: []
    }
  },
  created() {
    this.getProgram()
  },
  methods: {
    getProgram() {
      if (this.start === null) return
      if (this.loading) return

      this.loading = true
      qbi.set.loading(true)

      this.$axios.get(`program`, {
        params: {
          start: this.start,
          limit: this.limit
        }
      }).then(response => {
        this.loading = false
        qbi.set.loading(false)

        if (response.data?.data) {
          this.start = response.data.data.length < this.limit ? null : this.start + this.limit
          this.programs = [...this.programs, ...response.data.data]
        }
      }).catch(error => {
        this.loading = false
        qbi.set.loading(false)
        console.error(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.program-title {
  margin: 16px;
  font-size: 16px;
  font-weight: 700;
}

.program-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
}
</style>
