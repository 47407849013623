<template>
  <div class="navigation">
    <a v-on:click="goBack">
      <IconArrowLeft class="icon" />
    </a>
    <div class="title">Infaq</div>
  </div>
  <div class="content" v-if="transaction">
    <div class="content-wrapper">
      <div class="transaction-header" v-if="transaction.status === 'pending'">
        <IconCheckCard class="icon" />
        <div class="title">Menunggu Pembayaran</div>
        <div class="subtitle">Segera selesaikan pembayaran infaq Anda</div>
      </div>
      <div class="transaction-header" v-if="transaction.status === 'settlement'">
        <IconHandShake class="icon" />
        <div class="title">Jazakumullah Khairan Katsiran</div>
        <div class="subtitle">Infaq Anda telah diterima dan akan segera disalurkan</div>
      </div>
      <div class="transaction-header" v-if="transaction.status === 'expire'">
        <IconMoreTime class="icon" />
        <div class="title">Transaksi Dibatalkan</div>
        <div class="subtitle">Batas waktu pembayaran infaq Anda telah habis</div>
      </div>
      <router-link :to="`/${transaction.program.slug}`" class="transaction-body">
        <div class="transaction-image" :style="{ backgroundImage: `url(${transaction.program.image})` }" ref="image"></div>
        <div class="transaction-program">{{ transaction.program.name }}</div>
        <div class="transaction-info">
          <div class="info-item">
            <IconCalendarMonth class="icon icon-gray" />
            <div class="info-detail">
              <div class="label">Tanggal</div>
              <div class="value"><strong>{{ $filters.dateFormat(transaction.transaction_time, "DD MMMM YYYY") }}</strong></div>
            </div>
          </div>
          <div class="info-item">
            <IconVote class="icon icon-gray" />
            <div class="info-detail">
              <div class="label">Jumlah</div>
              <div class="value"><strong>Rp {{ $filters.numberFormat(transaction.amount) }}</strong></div>
            </div>
          </div>
          <div class="info-item">
            <IconPayment class="icon icon-gray" />
            <div class="info-detail">
              <div class="label">Metode Bayar</div>
              <div class="value"><strong>{{ transaction.type.name }}</strong></div>
            </div>
          </div>
          <div class="info-item">
            <IconInfo class="icon icon-gray" />
            <div class="info-detail">
              <div class="label">ID Infaq</div>
              <div class="value"><strong>{{ transaction.order_id }}</strong></div>
            </div>
          </div>
        </div>
        <div class="transaction-spacer"></div>
        <div class="transaction-status">
          <div class="title">Status Pembayaran</div>
          <button type="button" class="btn" v-bind:class="paymentStyle()">{{ paymentLabel() }}</button>
        </div>
      </router-link>
    </div>
  </div>
  <div class="bottom" v-if="transaction">
    <button type="button" class="btn" v-bind:class="{ 'btn-secondary': transaction.status === 'pending', 'btn-outline-secondary': transaction.status !== 'pending' }" v-on:click="actionButton">{{ transaction.status === 'pending' ? `Detail Pembayaran` : `Infaq Lagi` }}</button>
  </div>
</template>

<script>
import qbi from "@/qbi"

import IconArrowLeft from "@/assets/image/icon/arrow-left.svg"
import IconCheckCard from "@/assets/image/icon/check-card.svg"
import IconHandShake from "@/assets/image/icon/handshake.svg"
import IconMoreTime from "@/assets/image/icon/more-time.svg"
import IconCalendarMonth from "@/assets/image/icon/calendar-month.svg"
import IconVote from "@/assets/image/icon/how-to-vote.svg"
import IconPayment from "@/assets/image/icon/payment.svg"
import IconInfo from "@/assets/image/icon/info.svg"

export default {
  name: "ProgramDetail",
  inject: ["$axios"],
  components: {
    IconArrowLeft,
    IconCheckCard,
    IconHandShake,
    IconMoreTime,
    IconCalendarMonth,
    IconVote,
    IconPayment,
    IconInfo
  },
  props: {
    order_id: String
  },
  data() {
    return {
      transaction: null
    }
  },
  created() {
    qbi.set.header(false)
    this.getTransaction()
  },
  mounted() {
    this.resizeImage()
    window.addEventListener("resize", this.resizeImage)
  },
  unmounted() {
    this.active = false
    window.removeEventListener("resize", this.resizeImage)
  },
  updated() {
    this.resizeImage()
  },
  methods: {
    goBack() {
      if (window.history.state.back) this.$router.go(-1)
      else this.$router.replace({ name: "transaction-list" })
    },
    resizeImage() {
      if (this.$refs.image) {
        this.$refs.image.style.height = `${(this.$refs.image.clientWidth * 135 / 360)}px`
      }
    },
    paymentStyle() {
      let style = `btn-disabled`
      switch (this.transaction?.status) {
        case "pending": style = `btn-warning`; break
        case "settlement": style = `btn-primary`; break
        case "expire": style = `btn-disabled`; break
      }
      return style
    },
    paymentLabel() {
      let label = `Dibatalkan`
      switch (this.transaction?.status) {
        case "pending": label = `Pending`; break
        case "settlement": label = `Berhasil`; break
        case "expire": label = `Dibatalkan`; break
      }
      return label
    },
    getTransaction() {
      qbi.set.loading(true)
      this.$axios.get(`transaction/${this.order_id}`).then(response => {
        qbi.set.loading(false)
        if (response.data?.data) this.transaction = response.data.data
      }).catch(error => {
        qbi.set.loading(false)
        if (error.response.status === 404) this.goBack()
      })
    },
    actionButton() {
      if (this.transaction) {
        if (this.transaction.status === `pending`) this.$router.push({ name: "transaction-payment", params: { order_id: this.transaction.order_id } })
        else if (this.transaction.status !== `pending`) this.$router.push({ name: "program-checkout", params: { slug: this.transaction.program.slug } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.transaction-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 16px;
  background-color: #FFFFFF;
  border: 1px solid #EAEAEA;
  -webkit-border-radius: 6px;
  border-radius: 6px;

  >.icon {
    height: 36px;
  }

  >.title {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
  }
}

.transaction-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
  background-color: #FFFFFF;
  border: 1px solid #EAEAEA;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;

  >.transaction-image {
    width: 100%;
    height: 180px;
    background-color: #E5E5E5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  >.transaction-program {
    padding: 0 16px;
    font-size: 12px;
    font-weight: 700;
  }

  >.transaction-info {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
    padding: 0 16px;

    >.info-item {
      flex: 0 0 50%;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
      overflow: hidden;

      >.info-detail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
        line-height: normal;

        >.label {
          color: #999999;
        }
      }
    }
  }

  >.transaction-spacer {
    margin: 0 -16px;
    height: 1px;
    background-color: #EAEAEA;
  }

  >.transaction-status {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;

    >.title {
      font-size: 16px;
      font-weight: 700;
    }

    >button {
      height: 34px;
      -webkit-border-radius: 17px;
      border-radius: 17px;
    }
  }
}
</style>