<template>
  <div class="dialog-wrapper">
    <div class="dialog-backdrop"></div>
    <div class="dialog-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonDialog",
  props: {
  },
  components: {
  }
}
</script>

<style lang="scss" scoped></style>
