import moment from "moment"
import formatNumber from "simple-format-number"

export default {
  install: (app) => {
    app.config.globalProperties.$filters = {
      numberFormat(number, fractionDigits = 0, decimal = ",", grouping = ".", _default = "0") {
        if (parseFloat(number)) return formatNumber(parseFloat(number), { fractionDigits, symbols: { decimal, grouping } })
        return _default
      },
      numberParse(number) {
        if (number) return parseInt(number.toString().replace(/,/g, ""))
        return 0
      },
      dateFormat(date, format = "DD/MM/YYYY") {
        return moment(date).locale("id").format(format)
      },
      timeAgo(time) {
        const now = moment().locale("id")
        const date = moment(time).locale("id")
        if (now.isSame(date, "day")) return date.format("HH:mm")
        else if (now.isSame(date, "year")) return date.format("DD MMM")
        else return date.format("MMM YY")
      },
      timeRemain(time) {
        let remain = ``
        if (time < 60000) remain = `${Math.floor(time / 1000)} Detik`
        else if (time < 3600000) remain = `${Math.floor(time / 60000)} Menit`
        else if (time < 86400000) remain = `${Math.floor(time / 3600000)} Jam`
        else if (time < 604800000) remain = `${Math.floor(time / 86400000)} Hari`
        else if (time < 2629746000) remain = `${Math.floor(time / 604800000)} Minggu`
        else if (time < 31556952000) remain = `${Math.floor(time / 2629746000)} Bulan`
        else remain = `${Math.floor(time / 31556952000)} Tahun`
        return remain
      },
      timeCountdown(time) {
        let remain = time
        const hour = `${Math.floor(remain / 3600000)}`.padStart(2, "0")
        remain = remain % 3600000
        const minute = `${Math.floor(remain / 60000)}`.padStart(2, "0")
        remain = remain % 60000
        const second = `${Math.floor(remain / 1000)}`.padStart(2, "0")
        return `${hour}:${minute}:${second}`
      }
    }
  }
}