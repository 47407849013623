<template>
  <div class="navigation" ref="navigation">
    <a v-on:click="goBack">
      <IconArrowLeft class="icon" />
    </a>
    <div class="title">Donatur</div>
  </div>
  <div class="content" ref="content">
    <div class="content-wrapper">
      <div class="card-container" v-for="user in users" v-bind:key="user.id">
        <div class="card-body">
          <div class="program-user">
            <div class="user-item">
              <div class="user-header">
                <div class="header-image" :style="{ backgroundImage: `url(${user.image})` }">
                  <IconPerson class="icon icon-gray" v-if="!user.image" />
                </div>
                <div class="header-caption">
                  <div class="title">{{ user.name }}</div>
                  <div class="subtitle">Berinfaq sebesar <strong>Rp {{ $filters.numberFormat(user.amount) }}</strong></div>
                </div>
                <div class="header-time">{{ $filters.timeAgo(user.transaction_time) }}</div>
              </div>
              <div class="user-content" v-if="user.desc">“{{ user.desc }}”</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-container" v-if="!users.length">
        <div class="card-body">
          <div class="card-empty">
            <div class="title">Belum Ada Donatur</div>
            <div class="subtitle">Belum ada donatur dari program ini</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qbi from "@/qbi"

import IconArrowLeft from "@/assets/image/icon/arrow-left.svg"
import IconPerson from "@/assets/image/icon/person.svg"

export default {
  name: "ProgramDetailUser",
  inject: ["$axios"],
  components: {
    IconArrowLeft,
    IconPerson
  },
  props: {
    slug: String
  },
  data() {
    return {
      start: 0,
      limit: 20,
      loading: false,
      program: null,
      users: []
    }
  },
  created() {
    qbi.set.header(false)
    this.getProgram()
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    goBack() {
      if (window.history.state.back) this.$router.go(-1)
      else this.$router.replace({ name: "program-detail", slug: this.slug })
    },
    onScroll() {
        if (this.program && this.$refs.navigation && this.$refs.content) {
          const scrollTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) - this.$refs.navigation.offsetHeight
          const height = window.innerHeight
          if (scrollTop + height >= this.$refs.content.offsetHeight - 200) {
            this.getUser()
          }
        }
    },
    getProgram() {
      qbi.set.loading(true)
      this.$axios.get(`program/${this.slug}`).then(response => {
        qbi.set.loading(false)
        if (response.data?.data) {
          this.program = response.data.data
          this.getUser()
        }
      }).catch(error => {
        qbi.set.loading(false)
        console.error(error)
      })
    },
    getUser() {
      if (this.start === null) return
      if (this.loading) return
      
      this.loading = true
      qbi.set.loading(true)

      this.$axios.get(`program/${this.program.id}/user`, {
        params: {
          start: this.start,
          limit: this.limit
        }
      }).then(response => {
        this.loading = false
        qbi.set.loading(false)
        if (response.data?.data) {
          this.start = response.data.data.length < this.limit ? null : this.start + this.limit
          this.users = [...this.users, ...response.data.data]
        }
      }).catch(error => {
        this.loading = false
        qbi.set.loading(false)
        console.error(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.program-user {
  display: flex;
  flex-direction: column;
  gap: 24px;

  >.user-item {
    display: flex;
    flex-direction: column;
    gap: 16px;

    >.user-header {
      display: flex;
      align-items: center;
      gap: 10px;

      >.header-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        background-color: #EAEAEA;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-border-radius: 17px;
        border-radius: 17px;

        >.icon {
          width: 20px;
        }
      }

      >.header-caption {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
        line-height: normal;

        >.title {
          font-size: 14px;
          font-weight: 700;
        }

        >.subtitle {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }

      >.header-time {
        align-self: flex-start;
        color: #999999;
      }
    }
  }

  >.user-content {
    img {
      max-width: 100%;
    }
  }
}
</style>
