<template>
  <div class="program-recommendation">
    <div class="program-list">
      <ProgramCard v-bind:flex="true" v-bind:program="program" v-for="program in programs" :key="program.id" />
    </div>
  </div>
</template>

<script>
import ProgramCard from "./card"

export default {
  name: "ProgramRecommendation",
  inject: ["$axios"],
  components: {
    ProgramCard
  },
  data() {
    return {
      programs: []
    }
  },
  created() {
    this.getProgram()
  },
  methods: {
    getProgram() {
      this.$axios.get(`program`, {
        params: {
          pinned: 1
        }
      }).then(response => {
        if (response.data?.data) this.programs = response.data.data
      }).catch(error => {
        console.error(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.program-title {
  margin: 16px;
  font-size: 16px;
  font-weight: 700;
}

.program-list {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding: 0 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
