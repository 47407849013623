<template>
  <div class="card-container">
    <div class="card-header">
      <div class="title">{{ card.title }}</div>
      <span class="action" v-on:click="action">{{ card.label }}</span>
    </div>
    <div class="card-body">
      <div class="card-desc">{{ card.desc }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CommonCard",
  props: {
    card: Object
  },
  components: {
  },
  methods: {
    action() {
      this.$emit("callback", { action: this.card.action })
    }
  }
}
</script>

<style lang="scss" scoped>
.action {
  cursor: pointer;
}
</style>
