import { createApp } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import { createMetaManager } from "vue-meta"
import App from "./App.vue"

import qbi from "./qbi"
import constant from "./helpers/constant"
import filter from "./helpers/filter"
import axios from "./plugins/axios"
import toast from "./plugins/toast"

import ProgramList from "./components/program"
import ProgramDetail from "./components/program/detail"
import ProgramUpdate from "./components/program/update"
import ProgramUser from "./components/program/user"
import ProgramCheckout from "./components/program/checkout"
import OrganizationDetail from "./components/organization/detail"
import TransactionIndex from "./components/transaction"
import TransactionDetail from "./components/transaction/detail"
import TransactionPayment from "./components/transaction/payment"

import "./assets/css/main.scss"

const app = createApp(App)
qbi.actions.initialize()

app.provide("constant", constant)

app.use(filter)
app.use(axios)
app.use(toast)

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "program-list",
            component: ProgramList
        },
        {
            path: "/:slug",
            name: "program-detail",
            component: ProgramDetail,
            meta: { title: "Program Detail" },
            props: true
        },
        {
            path: "/:slug/update",
            name: "program-update",
            component: ProgramUpdate,
            meta: { title: "Program Detail - Kabar Terbaru" },
            props: true
        },
        {
            path: "/:slug/user",
            name: "program-user",
            component: ProgramUser,
            meta: { title: "Program Detail - Donatur" },
            props: true
        },
        {
            path: "/:slug/checkout",
            name: "program-checkout",
            component: ProgramCheckout,
            meta: { title: "Program Detail - Checkout" },
            props: true
        },
        {
            path: "/organization/:slug",
            name: "organization-detail",
            component: OrganizationDetail,
            meta: { title: "Penggalang Dana" },
            props: true
        },
        {
            path: "/transaction",
            name: "transaction-list",
            component: TransactionIndex,
            meta: { title: "Riwayat Infaq" }
        },
        {
            path: "/transaction/:order_id",
            name: "transaction-detail",
            component: TransactionDetail,
            meta: { title: "Riwayat Infaq" },
            props: true
        },
        {
            path: "/transaction/:order_id/payment",
            name: "transaction-payment",
            component: TransactionPayment,
            meta: { title: "Riwayat Infaq - Pembayaran" },
            props: true
        }
    ]
})

app.use(router)
app.use(createMetaManager())

app.mount("#app")
