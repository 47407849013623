<template>
  <Transition>
    <div class="toast-container">
      <div class="toast" v-html="message" :style="style" @click="closeHandle"></div>
    </div>
  </Transition>
</template>
  
<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
export default {
  name: "CommonToast",
  props: {
    message: { type: String, required: true },
    backgroundColor: { type: String },
    color: { type: String },
    timeout: { type: Number, default: 2000, validate: function (val) { return val >= 0 } },
    clickToClose: { type: Boolean, default: true },
    close: { type: Function, required: true }
  },

  setup(props) {
    let innerTimeout = ref()
    const style = computed(
      () => { return { backgroundColor: props.backgroundColor ? props.backgroundColor : "#212121", color: props.color ? props.color : "#FFFFFF" } }
    )

    onMounted(() => {
      toClearTimeout()
      if (props.timeout > 0) innerTimeout.value = setTimeout(() => { props.close() }, props.timeout)
    })

    onUnmounted(() => { toClearTimeout() })

    const closeHandle = () => {
      if (props.clickToClose) props.close()
    }

    const toClearTimeout = () => {
      if (innerTimeout.value) {
        try {
          clearTimeout(innerTimeout.value)
        } catch (e) {
          console.error(e)
        }
      }
    }

    return { style, closeHandle }
  },
}
</script>
  
<style lang="scss" scoped>
.toast-container {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 16px;

  .toast {
    position: absolute;
    bottom: 62px;
    margin: 0 auto;
    padding: 12px 16px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    z-index: 99999;
  }
}

.v-enter-active {
  transition: opacity 1s;
}

.v-leave-active {
  transition: opacity 3s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>