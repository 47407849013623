<template>
  <div class="navigation">
    <a v-on:click="goBack">
      <IconArrowLeft class="icon" />
    </a>
    <div class="title">Pilih Nominal Infaq</div>
  </div>
  <div class="content" v-if="program">
    <div class="content-wrapper">
      <div class="card-container">
        <div class="card-body">
          <div class="amount-picker">
            <div class="picker-item" v-for="picker in pickers" v-bind:key="picker">
              <button type="button" class="picker-container" v-bind:class="{ 'active': picker === amount.data }" v-on:click="setAmount(picker)">
                <IconVote class="icon" v-if="picker !== amount.data" />
                <IconCheck class="icon icon-white" v-if="picker === amount.data" />
                <div class="item-label">Rp {{ $filters.numberFormat(picker) }}</div>
              </button>
            </div>
          </div>
        </div>
        <div class="card-spacer"></div>
        <div class="card-header">
          <div class="title">Nominal Lainnya</div>
        </div>
        <div class="card-body">
          <div class="input-container" v-bind:class="{ 'error': !amount.valid }">
            <div class="input-icon">Rp</div>
            <input type="text" inputmode="numeric" pattern="[0-9]*" class="input-form" v-model="formattedAmount" v-on:keydown="validateAmount" placeholder="0" maxlength="14" />
          </div>
          <div class="input-notes" v-bind:class="{ 'error': !amount.valid }">{{ amount.message }}</div>
        </div>
      </div>
      <div class="card-container">
        <div class="card-header">
          <div class="title">Metode Pembayaran</div>
        </div>
        <div class="card-body">
          <button class="payment-picker" v-on:click="payment.open = true">
            <IconAddCard class="icon" v-if="!payment.data" />
            <img :src="payment.data.image" class="icon" v-if="payment.data" />
            <div class="label">{{ payment.data ? payment.data.name : "Pilih Metode Pembayaran" }}</div>
            <IconArrowLeft class="icon flip-y" v-if="!payment.data" />
            <span class="action" v-if="payment.data">Ubah</span>
          </button>
        </div>
      </div>
      <div class="card-container">
        <div class="card-header">
          <div class="title">Data Donatur</div>
        </div>
        <div class="card-body">
          <div class="form-content">
            <div class="form-group">
              <label>Nama Lengkap <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="form.name" placeholder="Masukkan nama Anda" />
              <div class="form-error" v-if="error.name">{{ error.name }}</div>
              <div class="input-group">
                <div class="text-muted flex">Sembunyikan nama saya</div>
                <div class="checkbox-toggle" v-bind:class="{'checked': form.anonymous}" v-on:click="form.anonymous = !form.anonymous"></div>
              </div>
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="email" class="form-control" v-model="form.email" placeholder="Masukkan email Anda" />
            </div>
            <div class="form-group">
              <label>No Whatsapp</label>
              <div class="input-group">
                <input type="text" class="form-control" v-model="form.area" placeholder="+62" :style="{ width: '44px' }" readonly />
                <input type="tel" class="form-control flex" v-model="form.phone" placeholder="Masukkan nomor whatsapp Anda" />
              </div>
            </div>
            <div class="form-group">
              <label>Kirim Doa</label>
              <textarea class="form-control" v-model="form.desc" placeholder="Sampaikan doa terbaik Anda disini" rows="4"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="card-container warning" v-if="program.information">
        <div class="card-header">
          <div class="title">Informasi</div>
        </div>
        <div class="card-body">
          <span>{{ program.information }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom" v-if="program">
    <button type="button" class="btn btn-primary" v-bind:disabled="!amount.data || !payment.data || !valid" v-on:click="charge">Lanjut Pembayaran</button>
  </div>
  <Transition>
    <ProgramCheckoutPayment class="fullscreen" v-bind:amount="amount.data" v-bind:selected="payment.data" v-bind:payments="payments" @callback="paymentCallback" v-if="payment.open" />
  </Transition>
</template>

<script>
import qbi from "@/qbi"

import IconArrowLeft from "@/assets/image/icon/arrow-left.svg"
import IconVote from "@/assets/image/icon/how-to-vote.svg"
import IconCheck from "@/assets/image/icon/check.svg"
import IconAddCard from "@/assets/image/icon/add-card.svg"

import ProgramCheckoutPayment from "./payment"

export default {
  name: "ProgramCheckout",
  inject: ["$axios"],
  components: {
    IconArrowLeft,
    IconVote,
    IconCheck,
    IconAddCard,
    ProgramCheckoutPayment
  },
  props: {
    slug: String
  },
  computed: {
    formattedAmount: {
      get: function () {
        return this.$filters.numberFormat(this.amount.data, 0, ".", ",", "")
      },
      set: function (value) {
        this.amount.data = this.$filters.numberParse(value) || null
        return false
      }
    }
  },
  data() {
    return {
      valid: false,
      program: null,
      pickers: [],
      payments: [],
      amount: {
        valid: true,
        message: "Minimal nominal donasi sebesar Rp 2.000",
        data: null
      },
      payment: {
        open: false,
        data: null
      },
      form: {
        name: "",
        anonymous: false,
        email: "",
        phone: "",
        desc: ""
      },
      error: {}
    }
  },
  created() {
    window.fbq("trackCustom", "Program Checkout", { slug: this.slug })
    qbi.set.header(false)
    this.initPicker()
    this.getProgram()
  },
  updated() {
    let min = 2000
    let max = 999999999
    let valid = true
    let text = `Minimal`
    let number = min
    if (this.payment.data !== null) {
      min = this.payment.data.min
      max = this.payment.data.max
    }
    if (this.amount.data !== null) {
      if (this.amount.data < min) {
        number = min
        valid = false
      } else if (this.amount.data > max) {
        text = `Maksimal`
        number = max
        valid = false
      }
    }
    this.valid = valid
    this.amount.valid = valid
    this.amount.message = `${text} nominal donasi sebesar Rp ${this.$filters.numberFormat(number)}`
  },
  methods: {
    goBack() {
      if (window.history.state.back) this.$router.go(-1)
      else this.$router.replace({ name: "program-detail", slug: this.slug })
    },
    initPicker() {
      this.pickers = [10000, 25000, 50000, 100000]
    },
    getProgram() {
      qbi.set.loading(true)
      this.$axios.get(`program/${this.slug}`).then(response => {
        qbi.set.loading(false)
        if (response.data?.data) {
          this.program = response.data.data
          this.getPaymentType()
        }
      }).catch(error => {
        qbi.set.loading(false)
        if (error.response.status === 404) this.goBack()
      })
    },
    getPaymentType() {
      this.$axios.get(`payment/type/grouped`).then(response => {
        if (response.data?.data && response.data?.data.length) this.payments = response.data.data
      }).catch(error => {
        console.error(error)
      })
    },
    validateAmount(event) {
      const allowedKey = [8, 9, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
      if (event.shiftKey || allowedKey.indexOf(event.keyCode) === -1) event.preventDefault()
    },
    setAmount(value) {
      this.amount.data = value
    },
    paymentCallback(event) {
      this.payment.open = false
      if (event.data) this.payment.data = event.data
    },
    getHistory() {
      let history = []
      try {
        history = JSON.parse(window.localStorage.getItem("history")) || []
      } catch (error) {
        console.error(error)
      }
      return history
    },
    updateHistory(order_id) {
      let history = this.getHistory()
      history.push(order_id)
      window.localStorage.setItem("history", JSON.stringify(history))
    },
    validate() {
      this.error = {}
      if (!this.form.name) this.error.name = `Nama lengkap tidak boleh kosong`
      return Object.keys(this.error).length === 0
    },
    charge() {
      if (!this.valid) return
      if (!this.validate()) return

      window.fbq("trackCustom", "Program Charge", { slug: this.slug })

      const data = {
        program_id: this.program.id,
        type: this.payment.data?.code,
        amount: this.amount.data,
        is_anonymous: this.form.anonymous ? 1 : 2,
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        desc: this.form.desc
      }

      qbi.set.loading(true)
      this.$axios.post(`transaction`, data).then(response => {
        qbi.set.loading(false)
        if (response.data?.data && response.data.data.order_id) {
          window.fbq("trackCustom", "Transaction Created", { order_id: response.data.data.order_id })
          this.updateHistory(response.data.data.order_id)
          this.$router.push({ name: "transaction-payment", params: { order_id: response.data.data.order_id } })
        }
      }).catch(error => {
        qbi.set.loading(false)
        console.error(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.amount-picker {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;

  >.picker-item {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    padding: 8px;

    >.picker-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      height: 41px;
      background-color: #F8F8F8;
      font-size: 14px;
      font-weight: 700;
      border: none;
      -webkit-border-radius: 4px;
      border-radius: 4px;

      &.active {
        color: #FFFFFF;
        background-color: #58A79E;
      }
    }
  }
}

.input-container {
  display: flex;
  align-items: stretch;
  background-color: #F8F8F8;
  padding: 0;
  height: 41px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;

  >.input-icon {
    align-self: center;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 700;
  }

  >.input-form {
    flex: 1;
    padding: 0 16px;
    border: none;
    background: none;
    text-align: right;
    font-size: 16px;
    font-weight: 700;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  &.error {
    border: 1px solid #F44336;
  }
}

.input-notes {
  margin-top: 10px;

  &.error {
    color: #F44336;
  }
}

.payment-picker {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  text-align: left;
  background-color: transparent;

  >img {
    &.icon {
      height: 32px;
    }
  }

  >.label {
    flex: 1;
    font-size: 12px;
    font-weight: 400;
  }

  >.action {
    font-size: 12px;
    font-weight: 400;
    color: #58A79E;
  }
}
</style>
