import { reactive } from 'vue'

const qbi = ({
  state: reactive({
    loading: false,
    header: false
  }),
  get: {
    loading() {
      return qbi.state.loading
    },
    header() {
      return qbi.state.header
    }
  },
  set: {
    loading(loading) {
      qbi.state.loading = loading
    },
    header(header) {
      qbi.state.header = header
    }
  },
  actions: {
    initialize() {
      qbi.state.loading = false
      qbi.state.header = false
    }
  }
})

export default qbi