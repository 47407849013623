<template>
  <CommonDialog>
    <div class="dialog-header">
      <button type="button" class="dialog-close" v-on:click="cancel">
        <IconClose class="icon" />
      </button>
      <div class="title">Panduan Pembayaran</div>
    </div>
    <div class="dialog-body">
      <div class="content-wrapper" v-if="transaction">
        <div class="instruction-header">
          <div class="title">Nomor Virtual Account</div>
          <div class="highlight">
            <span>{{ action.value }}</span>
            <strong class="action" v-on:click="copyNumber">Salin</strong>
          </div>
          <div class="payment-type">
            <img :src="transaction.type.image" class="icon" />
            <div class="label">{{ transaction.type.name }}</div>
          </div>
        </div>
        <div class="card-container" v-for="(instruction, index) in transaction.type.instruction" v-bind:key="index">
          <div class="card-header" v-on:click="toggleContent(index)">
            <div class="title">{{ instruction.title }}</div>
            <IconArrowLeft class="icon" v-bind:class="{ 'hidden': !instructions[index]?.open }" />
          </div>
          <Transition>
            <div class="card-body slide-up" :style="{ height: instructions[index]?.size || 'auto' }" v-if="!instructions.length || instructions[index].open" ref="contents">
              <div class="card-content" v-html="instruction.body"></div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </CommonDialog>
</template>

<script>
import IconClose from "@/assets/image/icon/close.svg"
import IconArrowLeft from "@/assets/image/icon/arrow-left.svg"

import CommonDialog from "@/components/common/dialog"

export default {
  name: "ProgramCheckoutPayment",
  inject: ["$toast"],
  components: {
    IconClose,
    IconArrowLeft,
    CommonDialog
  },
  props: {
    transaction: Object,
    action: Object
  },
  data() {
    return {
      instructions: []
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.contents) {
        this.$refs.contents.forEach((content, index) => {
          this.instructions[index] = { open: true, size: `${content.offsetHeight}px` }
        })
      }
    }, 300)
  },
  methods: {
    copyNumber() {
      navigator.clipboard.writeText(this.action.value)
      this.$toast("Nomor virtual account telah berhasil disalin")
    },
    toggleContent(index) {
      this.instructions[index].open = !this.instructions[index].open
    },
    cancel() {
      this.$emit("callback", { action: "cancel" })
    },
    close(data) {
      if (this.amount === null || (this.amount >= data.min && this.amount <= data.max)) {
        this.$emit("callback", { action: "close", data: data })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.instruction-header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #FFFFFF;
  border: 1px solid #EAEAEA;
  -webkit-border-radius: 6px;
  border-radius: 6px;

  >.title {
    align-self: center;
    font-size: 16px;
    font-weight: 700;
  }

  >.highlight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    line-height: normal;
    background-color: #F8F8F8;
    -webkit-border-radius: 6px;
    border-radius: 6px;

    >.action {
      color: #58A79E;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.payment-type {
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    &.icon {
      height: 32px;
    }
  }
}

.card-container {
  gap: 0;

  >.card-header {
    &:hover {
      cursor: pointer;
    }

    >.icon {
      transition: transform 0.2s ease 0.1s;
      transform: rotate(90deg);

      &.hidden {
        transform: rotate(-90deg);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  >.card-body {
    >.card-content {
      &::before {
        content: "";
        display: block;
        height: 16px;
      }
    }
  }
}
</style>
