<template>
  <div class="navigation">
    <a v-on:click="goBack">
      <IconArrowLeft class="icon" />
    </a>
    <div class="title">{{ program?.name || "" }}</div>
  </div>
  <div class="content" v-if="program">
    <div class="program-detail">
      <div class="program-content" ref="content">
        <div class="program-image" :style="{ backgroundImage: `url(${program.image})` }" ref="image"></div>
        <div class="program-caption">
          <div class="title">{{ program.name }}</div>
          <div class="subtitle">{{ program.organization.name }}</div>
        </div>
        <div class="program-info">
          <div class="info-item">
            <IconGroup class="icon icon-gray" />
            <div class="info-detail">
              <div class="label">Jumlah Infaq</div>
              <div class="value"><strong>{{ $filters.numberFormat(program.transaction) }}</strong> infaq</div>
            </div>
          </div>
          <div class="info-item">
            <IconAccessTime class="icon icon-gray" />
            <div class="info-detail">
              <div class="label">Batas Waktu</div>
              <div class="value">
                <strong v-if="program.expired_at && program.expired_in >= 1000">{{ $filters.timeRemain(program.expired_in) }} Lagi</strong>
                <strong v-if="program.expired_at && program.expired_in < 1000">Berakhir</strong>
                <span class="text-lg" v-if="!program.expired_at">∞</span>
              </div>
            </div>
          </div>
          <div class="info-item">
            <IconVote class="icon icon-gray" />
            <div class="info-detail">
              <div class="label">Terkumpul</div>
              <div class="value text-primary"><strong>Rp {{ $filters.numberFormat(program.collected) }}</strong></div>
            </div>
          </div>
          <div class="info-item">
            <IconArchive class="icon icon-gray" />
            <div class="info-detail">
              <div class="label">Target</div>
              <div class="value">
                <strong v-if="program.target">Rp {{ $filters.numberFormat(program.target) }}</strong>
                <span class="text-lg" v-if="!program.target">∞</span>
              </div>
            </div>
          </div>
        </div>
        <div class="program-progress" v-if="program.target">
          <div class="progress-track">
            <div class="progress-fill" :style="{ width: progress + '%' }"></div>
          </div>
        </div>
        <button type="button" class="btn btn-secondary" v-bind:disabled="!program.is_active" v-on:click="checkout()">Infaq Sekarang</button>
      </div>
      <div class="content-wrapper">
        <ProgramOrganization v-bind:program="program" />
        <CommonCard v-bind:card="{ title: `Deskripsi`, label: `Lihat Detail`, desc: program.short_desc, action: `open` }" @callback="descriptionCallback" />
        <ProgramUpdate v-bind:program="program" />
        <ProgramUser v-bind:program="program" />
      </div>
    </div>
  </div>
  <Transition>
    <div class="bottom slide-up" :style="{height: '68px'}" v-if="program && bottom">
      <button type="button" class="btn btn-secondary" v-bind:disabled="!program.is_active" v-on:click="checkout">Infaq Sekarang</button>
    </div>
  </Transition>
  <Transition>
    <ProgramDescription class="fullscreen" v-bind:program="program" @callback="descriptionCallback" v-if="description" />
  </Transition>
</template>

<script>
import moment from "moment"

import qbi from "@/qbi"

import IconArrowLeft from "@/assets/image/icon/arrow-left.svg"
import IconGroup from "@/assets/image/icon/group.svg"
import IconAccessTime from "@/assets/image/icon/access-time.svg"
import IconVote from "@/assets/image/icon/how-to-vote.svg"
import IconArchive from "@/assets/image/icon/archive.svg"

import CommonCard from "@/components/common/card"
import ProgramOrganization from "./organization"
import ProgramUpdate from "./update"
import ProgramUser from "./user"
import ProgramDescription from "../description"

export default {
  name: "ProgramDetail",
  inject: ["$axios"],
  components: {
    IconArrowLeft,
    IconAccessTime,
    IconVote,
    IconGroup,
    IconArchive,
    CommonCard,
    ProgramOrganization,
    ProgramUpdate,
    ProgramUser,
    ProgramDescription
  },
  props: {
    slug: String
  },
  data() {
    return {
      program: null,
      active: true,
      description: false,
      bottom: false,
      progress: 0
    }
  },
  created() {
    window.fbq("trackCustom", "Program Detail", { slug: this.slug })
    qbi.set.header(false)
    this.getProgram()
  },
  mounted() {
    this.resizeImage()
    window.addEventListener("resize", this.resizeImage)
    window.addEventListener("scroll", this.onScroll, true)
  },
  unmounted() {
    this.active = false
    window.removeEventListener("resize", this.resizeImage)
    window.removeEventListener("scroll", this.onScroll, true)
  },
  updated() {
    this.resizeImage()
    this.toggleBottom()
  },
  methods: {
    goBack() {
      if (window.history.state.back) this.$router.go(-1)
      else this.$router.replace({ name: "program-list" })
    },
    resizeImage() {
      if (this.$refs.image) {
        this.$refs.image.style.height = `${(this.$refs.image.clientWidth * 135 / 360)}px`
      }
    },
    toggleBottom() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const offset = this.$refs.content?.offsetHeight || 420
      this.bottom = scrollTop > offset
    },
    onScroll() {
      this.bottom = true
      this.toggleBottom()
    },
    countdownExpire() {
      if (this.active && this.program.expired_at && this.program.expired_in >= 1000) {
        const duration = this.program.expired_in > 60000 ? (this.program.expired_in - 60000 > 15000 ? 15000 : this.program.expired_in - 60000) : 1000
        const now = moment()
        setTimeout(() => {
          this.program.expired_in -= moment().diff(now)
          if (this.program.expired_in < 1000) {
            this.program.is_active = false
          } else {
            this.countdownExpire()
          }
        }, duration)
      }
    },
    getProgram() {
      qbi.set.loading(true)
      this.$axios.get(`program/${this.slug}`).then(response => {
        qbi.set.loading(false)
        if (response.data?.data) {
          this.program = response.data.data
        }
        this.progress = this.program.target ? (this.program.collected / this.program.target * 100) : 100
        if (this.program && this.program.expired_at && this.program.expired_in >= 1000) this.countdownExpire()
        if (this.progress > 100) this.progress = 100
      }).catch(error => {
        qbi.set.loading(false)
        if (error.response.status === 404) this.goBack()
      })
    },
    descriptionCallback(event) {
      this.description = event?.action === "open"
    },
    checkout() {
      this.$router.push({ name: "program-checkout", params: { slug: this.slug } })
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom {
  padding: 0;
  height: 68px;

  >button {
    margin: 16px;
  }
}

.program-detail {
  flex: 100% 0 0;
  display: flex;
  flex-direction: column;


  >.program-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #EAEAEA;

    >.program-image {
      width: 100%;
      height: 180px;
      background-color: #E5E5E5;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    >.program-caption {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 0 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      >.title {
        font-size: 14px;
        font-weight: 700;
        white-space: normal;
      }

      >.subtitle {
        font-size: 12px;
        font-weight: 500;
        color: #999999;
      }
    }

    >.program-info {
      display: flex;
      flex-wrap: wrap;
      row-gap: 16px;
      padding: 0 16px;

      >.info-item {
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        gap: 8px;
        overflow: hidden;

        >.info-detail {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;
          line-height: normal;

          >.label {
            color: #999999;
          }
        }
      }
    }

    >.program-progress {
      display: flex;
      padding: 0 16px;

      >.progress-track {
        position: relative;
        width: 100%;
        height: 6px;
        background-color: #EAEAEA;
        overflow: hidden;
        -webkit-border-radius: 4px;
        border-radius: 4px;

        >.progress-fill {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 6px;
          background-color: #58A79E;
          -webkit-border-radius: 4px;
          border-radius: 4px;
        }
      }
    }

    >button {
      margin: 0 16px 16px 16px;
    }
  }
}
</style>