<template>
  <div class="card-container">
    <div class="card-header">
      <div class="title">Donatur</div>
      <router-link :to="`/${program.slug}/user`" class="action" v-if="users.length">Lihat Detail</router-link>
    </div>
    <div class="card-body">
      <div class="program-user" v-if="users.length">
        <div class="user-item" v-for="user in users" v-bind:key="user.id">
          <div class="user-header">
            <div class="header-image" :style="{ backgroundImage: `url(${user.image})` }">
              <IconPerson class="icon icon-gray" v-if="!user.image" />
            </div>
            <div class="header-caption">
              <div class="title">{{ user.name }}</div>
              <div class="subtitle">Berinfaq sebesar <strong>Rp {{ $filters.numberFormat(user.amount) }}</strong></div>
            </div>
            <div class="header-time">{{ $filters.timeAgo(user.transaction_time) }}</div>
          </div>
          <div class="user-content" v-if="user.desc">“{{ user.desc }}”</div>
        </div>
      </div>
      <div class="card-empty" v-if="!users.length">
        <div class="title">Belum Ada Donatur</div>
        <div class="subtitle">Belum ada donatur dari program ini</div>
      </div>
    </div>
  </div>
</template>

<script>
import IconPerson from "@/assets/image/icon/person.svg"

export default {
  name: "ProgramDetailUser",
  inject: ["$axios"],
  components: {
    IconPerson
  },
  props: {
    program: Object
  },
  data() {
    return {
      users: []
    }
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.$axios.get(`program/${this.program.id}/user`, {
        params: {
          start: 0,
          limit: 3
        }
      }).then(response => {
        if (response.data?.data && response.data?.data.length) this.users = response.data.data
      }).catch(error => {
        console.error(error)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.program-user {
  display: flex;
  flex-direction: column;
  gap: 24px;

  >.user-item {
    display: flex;
    flex-direction: column;
    gap: 16px;

    >.user-header {
      display: flex;
      align-items: center;
      gap: 10px;

      >.header-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        background-color: #EAEAEA;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-border-radius: 17px;
        border-radius: 17px;

        >.icon {
          width: 20px;
        }
      }

      >.header-caption {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
        line-height: normal;

        >.title {
          font-size: 14px;
          font-weight: 700;
        }

        >.subtitle {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }

      >.header-time {
        align-self: flex-start;
        color: #999999;
      }
    }

    >.user-content {
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
