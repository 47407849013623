import Toast from "@/components/common/toast"
import { createApp } from "vue"

const install = (app) => {
  let container
  let toastApp
  
  const baseProps = {
    close: () => {
      if (toastApp) toastApp.unmount(container)
      container = document.querySelector("#toast-wrapper")
      if (container) document.getElementById("app").removeChild(container)
    }
  }

  const toast = (message) => {
    if (typeof message === "string") message = { message }

    const props = { ...baseProps, ...message }
    props.close()

    container = document.createElement("div")
    container.setAttribute("id", "toast-wrapper")
    container.setAttribute("class", "toast-wrapper")
    document.getElementById("app").appendChild(container)
    toastApp = createApp(Toast, props)
    toastApp.mount(container)
  }

  app.provide("$toast", toast)
}

export default install
