<template>
  <router-link :to="`/${program.slug}`" v-bind:class="{'flex': flex}" class="program-card">
    <div class="program-image" :style="{ backgroundImage: `url(${program.image})` }" ref="image"></div>
    <div class="program-content">
      <div class="program-info">
        <div class="info-item">
          <IconVote class="icon icon-gray" />
          <div class="info-detail">
            <div class="info-label">Terkumpul</div>
            <div class="info-value text-primary"><strong>Rp {{ $filters.numberFormat(program.collected) }}</strong></div>
          </div>
        </div>
        <div class="info-item">
          <IconGroup class="icon icon-gray" />
          <div class="info-detail">
            <div class="info-label">Jumlah Infaq</div>
            <div class="info-value"><strong>{{ $filters.numberFormat(program.transaction) }}</strong> infaq</div>
          </div>
        </div>
      </div>
      <div class="program-progress" v-if="program.target">
        <div class="progress-track">
          <div class="progress-fill" :style="{ width: progress + '%' }"></div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import IconVote from "@/assets/image/icon/how-to-vote.svg"
import IconGroup from "@/assets/image/icon/group.svg"

export default {
  name: "ProgramCard",
  props: {
    flex: Boolean,
    program: Object
  },
  data() {
    return {
      progress: 0
    }
  },
  components: {
    IconVote,
    IconGroup
  },
  created() {
    this.progress = this.program.target ? (this.program.collected / this.program.target * 100) : 100
    if (this.progress > 100) this.progress = 100
  },
  mounted() {
    this.resizeImage()
    window.addEventListener("resize", this.resizeImage)
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeImage)
  },
  methods: {
    resizeImage() {
      if (this.$refs.image) {
        this.$refs.image.style.height = `${(this.$refs.image.clientWidth * 124 / 328)}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.program-card {
  display: flex;
  flex-direction: column;

  &.flex {
    flex: 100% 0 0;
  }

  >.program-image {
    width: 100%;
    height: 180px;
    background-color: #E5E5E5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
  }

  >.program-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    padding: 16px;
    background-color: #FFFFFF;
    border: 1px solid #EAEAEA;
    border-top: none;
    -webkit-border-radius: 0 0 6px 6px;
    border-radius: 0 0 6px 6px;

    >.program-info {
      display: flex;

      >.info-item {
        flex: 1;
        display: flex;
        gap: 8px;
        align-items: center;
        overflow: hidden;

        >.info-detail {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;
          line-height: normal;

          >.info-label {
            color: #999999;
          }
        }
      }
    }

    >.program-progress {
      display: flex;

      >.progress-track {
        position: relative;
        width: 100%;
        height: 6px;
        background-color: #EAEAEA;
        overflow: hidden;
        -webkit-border-radius: 4px;
        border-radius: 4px;

        >.progress-fill {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 6px;
          background-color: #58A79E;
          -webkit-border-radius: 4px;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
