<template>
  <CommonDialog>
    <div class="dialog-header">
      <button type="button" class="dialog-close" v-on:click="cancel">
        <IconClose class="icon" />
      </button>
      <div class="title">Pilih Metode Pembayaran</div>
    </div>
    <div class="dialog-body">
      <div class="content-wrapper">
        <div class="payment-container" v-for="payment in payments" v-bind:key="payment.id">
          <div class="payment-header">
            <div class="title">{{ payment.name }}</div>
            <div class="subtitle">{{ payment.desc }}</div>
          </div>
          <div class="payment-list">
            <div class="payment-item" v-bind:class="{ 'disabled': amount && (amount < item.min || amount > item.max) }" v-on:click="close(item)" v-for="item in payment.type" v-bind:key="item.id">
              <img :src="item.image" />
              <div class="payment-caption">
                <div class="title">{{ item.name }}</div>
                <div class="subtitle">Maksimal Rp {{ $filters.numberFormat(item.max) }}</div>
              </div>
              <div class="payment-radio" v-bind:class="{ 'active': (amount === null || (amount >= item.min && this.amount <= item.max)) && selected?.id == item.id }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonDialog>
</template>

<script>
import IconClose from "@/assets/image/icon/close.svg"

import CommonDialog from "@/components/common/dialog"

export default {
  name: "ProgramCheckoutPayment",
  components: {
    IconClose,
    CommonDialog
  },
  props: {
    amount: Number,
    selected: Object,
    payments: Array
  },
  methods: {
    cancel() {
      this.$emit("callback", { action: "cancel" })
    },
    close(data) {
      if (this.amount === null || (this.amount >= data.min && this.amount <= data.max)) {
        this.$emit("callback", { action: "close", data: data })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #FFFFFF;
  border: 1px solid #EAEAEA;
  -webkit-border-radius: 6px;
  border-radius: 6px;

  >.payment-header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    >.title {
      font-size: 16px;
      font-weight: 700;
    }

    >.subtitle {
      color: #999999;
    }
  }

  >.payment-list {
    display: flex;
    flex-direction: column;
    gap: 24px;

    >.payment-item {
      display: flex;
      align-items: center;
      gap: 16px;

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        opacity: 0.3;

        &:hover {
          cursor: default;
        }
      }

      >img {
        width: 45px;
      }

      >.payment-caption {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;

        >.title {
          font-weight: 700;
        }

        >.subtitle {
          color: #999999;
        }
      }

      >.payment-radio {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border: 2px solid #999999;
        -webkit-border-radius: 10px;
        border-radius: 10px;

        &.active {
          border-color: #58A79E;

          &::before {
            content: "";
            width: 10px;
            height: 10px;
            background-color: #58A79E;
            -webkit-border-radius: 5px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
</style>
